import {AxiosPromise} from 'axios';
import api from './api';
import apiGraphQL from './apiGraphQL';
import {Coach, Organization, Team} from '../interfaces';

function postAdminUtil(params: {
  job: string;
  data: any;
  onError?: () => void;
  onSuccess?: () => void;
}) {
  const {job, data, onError, onSuccess} = params;

  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .post(`/admin/util`, {job, data})
        .then((response: any) => {
          resolve(response);
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((e: Error) => {
          reject(e);
          if (onError) {
            onError();
          }
        });
    } catch (e) {
      reject(e);
      if (onError) {
        onError();
      }
    }
  });
}

const getOrganizations = () => {
  return new Promise<Organization[]>((resolve, reject) => {
    try {
      apiGraphQL
        .post('/graphql', {
          query: `{
            adminOrganizations {
              id
              name
              country
              stateOrProvince
            }
          }`,
          variables: {},
        })
        .then(response => {
          resolve(response.data.data.adminOrganizations);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};

const getOrganization = (id: number) => {
  return new Promise<Organization>((resolve, reject) => {
    try {
      apiGraphQL
        .post('/graphql', {
          query: `query ($id: Int!) {
            adminOrganization(id: $id) {
              id
              name
              country
              stateOrProvince
              teams {
                id
                name
                teamSessionGroupsEnabled
                isPro
              }
            }
          }`,
          variables: {
            id,
          },
        })
        .then(response => {
          resolve(response.data.data.adminOrganization);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};

const getTeams = () => {
  return new Promise<Team[]>((resolve, reject) => {
    try {
      apiGraphQL
        .post('/graphql', {
          query: `{
            adminTeams {
              id
              name
              teamSessionGroupsEnabled
              isPro
            }
          }`,
          variables: {},
        })
        .then(response => {
          resolve(response.data.data.adminTeams);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};

const getTeam = (id: number) => {
  return new Promise<Team>((resolve, reject) => {
    try {
      apiGraphQL
        .post('/graphql', {
          query: `query ($id: Int!) {
          adminTeam(id: $id) {
              id
              name
              teamSessionGroupsEnabled
              isPro
              organization {
                id
                name
              }
              players {
                id
                number
                profile {
                  id
                  firstName
                  lastName
                  position
                }
              }
              coaches {
                id
                profile {
                  id
                  email
                  firstName
                  lastName
                }
              }
            }
          }`,
          variables: {
            id,
          },
        })
        .then(response => {
          resolve(response.data.data.adminTeam);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};

const createTeam = (input: {
  name: string;
  organizationId: number;
  teamSessionGroupsEnabled: boolean;
  isPro: boolean;
}) => {
  return new Promise<Team>((resolve, reject) => {
    try {
      apiGraphQL
        .post('/graphql', {
          query: `mutation CreateTeam($input: CreateTeamInput!) {
            adminCreateTeam(input: $input) {
              id,
              name,
            }
          }`,
          variables: {
            input,
          },
        })
        .then(response => {
          if (response.data.errors) {
            alert(
              response.data.errors
                .map((e: {message: string}) => e.message)
                .join('\n'),
            );
          }
          resolve(response.data.data.adminCreateTeam);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};

const createCoach = (input: {email: string; teamId: number}) => {
  return new Promise<Coach>((resolve, reject) => {
    try {
      apiGraphQL
        .post('/graphql', {
          query: `mutation CreateCoach($input: CreateCoachInput!) {
            adminCreateCoach(input: $input) {
              id,
            }
          }`,
          variables: {
            input,
          },
        })
        .then(response => {
          if (response.data.errors) {
            alert(
              response.data.errors
                .map((e: {message: string}) => e.message)
                .join('\n'),
            );
          }
          resolve(response.data.data.adminCreateCoach);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};

const deleteCoach = (input: {id: number}) => {
  return new Promise<Coach>((resolve, reject) => {
    try {
      apiGraphQL
        .post('/graphql', {
          query: `mutation DeleteCoach($input: DeleteCoachInput!) {
            adminDeleteCoach(input: $input) {
              id,
            }
          }`,
          variables: {
            input,
          },
        })
        .then(response => {
          if (response.data.errors) {
            alert(
              response.data.errors
                .map((e: {message: string}) => e.message)
                .join('\n'),
            );
          }
          resolve(response.data.data.adminDeleteCoach);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};

export {
  postAdminUtil,
  getOrganizations,
  getOrganization,
  getTeams,
  getTeam,
  createTeam,
  createCoach,
  deleteCoach,
};

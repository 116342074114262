import React, {useCallback, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import PageTitle from '../shared/PageTitle';
import {createCoach, deleteCoach, getTeam} from '../../api/adminAPI';
import {useParams} from 'react-router-dom';
import {
  Box,
  Button,
  FormGroup,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import BackButton from '../shared/BackButton';
import {Coach, Team} from '../../interfaces';
import styles from '../../styles';

interface Props {}

const AdminTeamDetail: React.FC<Props> = () => {
  const {t} = useTranslation();
  const {teamId} = useParams();

  const [team, setTeam] = React.useState<Team | null>(null);
  const [pending, setPending] = React.useState(false);
  const [showAddCoachDialog, setShowAddCoachDialog] = React.useState(false);
  const [addCoachEmail, setAddCoachEmail] = React.useState('');

  const fetchData = useCallback(async () => {
    setPending(true);
    const team = await getTeam(parseInt(teamId ?? ''));
    setTeam(team);
    setPending(false);
  }, [teamId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAddCoachClick = useCallback(() => {
    setShowAddCoachDialog(true);
  }, []);

  const handleAddCoachClose = useCallback(() => {
    setShowAddCoachDialog(false);
    setAddCoachEmail('');
  }, []);

  const handleOnCoachEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAddCoachEmail(e.target.value);
    },
    [],
  );

  const handleAddCoachSubmit = useCallback(async () => {
    setPending(true);
    await createCoach({
      email: addCoachEmail,
      teamId: parseInt(teamId ?? ''),
    });
    handleAddCoachClose();
    fetchData();
  }, [addCoachEmail, fetchData, handleAddCoachClose, teamId]);

  const handleDeleteCoach = useCallback(
    async (coach: Coach) => {
      setPending(true);
      // ask confirmation dialog
      const res = confirm(
        `Are you sure you want to delete this coach?\n${coach.profile?.email}`,
      );
      if (res) {
        // delete coach
        await deleteCoach({
          id: coach.id!,
        });
        fetchData();
      } else {
        setPending(false);
      }
    },
    [fetchData],
  );

  return (
    <>
      <Helmet>
        <title>
          {t('navigation.pageTitle', {
            pageName: t('pages.teamDetail', {teamName: team?.name}),
          })}
        </title>
      </Helmet>
      <Box>
        <PageTitle>{t('pages.teamDetail', {teamName: team?.name})}</PageTitle>
        <BackButton navigationUrl="/admin/teams">{t('pages.teams')}</BackButton>
        <Button disabled={pending} onClick={handleAddCoachClick}>
          Add Coach
        </Button>
        <Modal
          open={showAddCoachDialog}
          onClose={handleAddCoachClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={styles.modal}>
            <Typography variant="h4" sx={{mb: 2}}>
              Add Coach
            </Typography>
            <FormGroup>
              <TextField
                label="Email"
                name="name"
                variant="outlined"
                value={addCoachEmail}
                onChange={handleOnCoachEmailChange}
                sx={{mb: 2}}
                fullWidth
              />
              <Button
                disabled={pending || addCoachEmail.length === 0}
                onClick={handleAddCoachSubmit}>
                Submit
              </Button>
            </FormGroup>
          </Box>
        </Modal>
        <TableContainer sx={{mb: 1}} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...(team?.coaches ?? [])].map((coach: Coach) => (
                <TableRow key={coach.id}>
                  <TableCell>{`${coach.profile?.firstName} ${coach.profile?.lastName}`}</TableCell>
                  <TableCell>{coach.profile?.email}</TableCell>
                  <TableCell align="right">
                    <Button
                      onClick={() => handleDeleteCoach(coach)}
                      disabled={pending}>
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default React.memo(AdminTeamDetail);

import React, {useEffect} from 'react';
import {getOrganizations} from '../../api/adminAPI';
import {Organization} from '../../interfaces';
import {Box} from '@mui/material';
import PageTitle from '../shared/PageTitle';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';
import OrganizationsTable from './OrganizationsTable';

interface Props {}

const AdminOrganizations: React.FC<Props> = () => {
  const {t} = useTranslation();

  const [organizations, setOrganizations] = React.useState<Organization[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      // fetch organizations
      const orgs = await getOrganizations();
      setOrganizations(orgs);
    };
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {t('navigation.pageTitle', {pageName: t('pages.organizations')})}
        </title>
      </Helmet>
      <Box>
        <PageTitle>{t('pages.organizations')}</PageTitle>
        <OrganizationsTable organizations={organizations ?? []} />
      </Box>
    </>
  );
};

export default React.memo(AdminOrganizations);

import React, {useCallback, useEffect} from 'react';
import PageTitle from '../shared/PageTitle';
import {createTeam, getOrganization} from '../../api/adminAPI';
import {Organization} from '../../interfaces';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';
import BackButton from '../shared/BackButton';
import {useParams} from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import TeamsTable from './TeamsTable';
import styles from '../../styles';

const AdminOrganizationDetail = () => {
  const {t} = useTranslation();
  const {organizationId} = useParams();

  const [organization, setOrganization] = React.useState<Organization | null>(
    null,
  );
  const [showAddTeamDialog, setShowAddTeamDialog] = React.useState(false);
  const [pending, setPending] = React.useState(false);
  const [addTeamName, setAddTeamName] = React.useState('');
  const [addTeamTeamSessionGroupsEnabled, setAddTeamTeamSessionGroupsEnabled] =
    React.useState(false);
  const [addTeamIsPro, setAddTeamIsPro] = React.useState<boolean>(false);

  // fetch organization data
  const fetchData = useCallback(async () => {
    setPending(true);
    const org = await getOrganization(parseInt(organizationId ?? ''));
    setOrganization(org);
    setPending(false);
  }, [organizationId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAddTeamClick = useCallback(() => {
    setShowAddTeamDialog(true);
  }, []);

  const handleAddTeamClose = useCallback(() => {
    setShowAddTeamDialog(false);
    setAddTeamName('');
    setAddTeamTeamSessionGroupsEnabled(false);
    setAddTeamIsPro(false);
  }, []);

  const handleOnAddTeamNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAddTeamName(e.target.value);
    },
    [],
  );

  const handleOnAddTeamSessionGroupsEnabledChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAddTeamTeamSessionGroupsEnabled(e.target.checked);
    },
    [],
  );

  const handleOnAddTeamIsProChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAddTeamIsPro(e.target.checked);
    },
    [],
  );

  const handleAddTeamSubmit = useCallback(async () => {
    setPending(true);
    await createTeam({
      name: addTeamName,
      organizationId: parseInt(organizationId ?? ''),
      teamSessionGroupsEnabled: addTeamTeamSessionGroupsEnabled,
      isPro: addTeamIsPro,
    });
    handleAddTeamClose();
    fetchData();
  }, [
    addTeamIsPro,
    addTeamName,
    addTeamTeamSessionGroupsEnabled,
    fetchData,
    handleAddTeamClose,
    organizationId,
  ]);

  return (
    <>
      <Helmet>
        <title>
          {t('navigation.pageTitle', {
            pageName: t('pages.organizationDetail', {
              organizationName: organization?.name,
            }),
          })}
        </title>
      </Helmet>
      <Box>
        <PageTitle>
          {t('pages.organizationDetail', {
            organizationName: organization?.name,
          })}
        </PageTitle>
        <BackButton navigationUrl="/admin/organizations">
          {t('pages.organizations')}
        </BackButton>
        <Button sx={{mb: 2}} onClick={handleAddTeamClick} disabled={pending}>
          Add Team
        </Button>
        <Modal
          open={showAddTeamDialog}
          onClose={handleAddTeamClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={styles.modal}>
            <Typography variant="h4" sx={{mb: 2}}>
              Add Team
            </Typography>
            <FormGroup>
              <TextField
                label="Name"
                name="name"
                variant="outlined"
                value={addTeamName}
                onChange={handleOnAddTeamNameChange}
                sx={{mb: 2}}
                fullWidth
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="teamSessionGroupsEnabled"
                    value={addTeamTeamSessionGroupsEnabled}
                    onChange={handleOnAddTeamSessionGroupsEnabledChange}
                  />
                }
                label="Team Session Groups Enabled"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="isPro"
                    value={addTeamIsPro}
                    onChange={handleOnAddTeamIsProChange}
                  />
                }
                label="Is Pro"
              />
              <Button
                disabled={pending || addTeamName.length === 0}
                onClick={handleAddTeamSubmit}>
                Submit
              </Button>
            </FormGroup>
          </Box>
        </Modal>
        <TeamsTable teams={organization?.teams ?? []} />
      </Box>
    </>
  );
};

export default AdminOrganizationDetail;
